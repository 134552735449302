var exports = {};

function getOption(seachData) {
    var option = new Option(seachData.elementID, seachData.elementID);
    return option;
}

exports.updateRecentSearches = function (currentSearch, recentSearches) {
    return updateRecentSearches(currentSearch, recentSearches);
}

exports.processCurrentSearch = function (currentSearch) {
    return processCurrentSearch(currentSearch);
}

function processCurrentSearch(currentSearch) {
    var tripType = currentSearch.tripType.toLowerCase();
    if (tripType !== "roundtrip" && tripType !== "oneway") {
        throw "Invalid Search Type";
    }

    var month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug",
        "Sep", "Oct", "Nov", "Dec"];

    var originAirport = currentSearch.originAirport;
    var destinationAirport = currentSearch.destinationAirport;

    if (originAirport === null
        || originAirport.length != 3
        || destinationAirport === null
        || destinationAirport.length != 3
        || originAirport.toUpperCase() === destinationAirport.toUpperCase()) {
        throw "Invalid Origin/Destination Airport";
    }

    if (currentSearch.originTravelDate === null || isNaN(currentSearch.originTravelDate) == false) {
        throw "Invalid Origin Travel Date";
    }

    var originDate = new Date(currentSearch.originTravelDate);
    currentSearch.originTravelDate = {
        "dateTime": originDate,
        "year": originDate.getFullYear(),
        "monthOfYear": originDate.getMonth() + 1,
        "dayOfMonth": originDate.getDate()
    };

    currentSearch.elementID = originAirport.toUpperCase() + " to "
		+ destinationAirport.toUpperCase() + " "
		+ month[originDate.getMonth()] + " " + originDate.getDate();

    currentSearch.originAirport = currentSearch.originAirport.toUpperCase();
    currentSearch.destinationAirport = currentSearch.destinationAirport.toUpperCase();

    if (tripType === "roundtrip") {

        if (currentSearch.destinationTravelDate === null || isNaN(currentSearch.destinationTravelDate) == false) {
            throw "Invalid Return Travel Date";
        }
        var destinationTravelDate = new Date(
            currentSearch.destinationTravelDate);
        currentSearch.destinationTravelDate = {
            "dateTime": destinationTravelDate,
            "year": destinationTravelDate.getFullYear(),
            "monthOfYear": destinationTravelDate.getMonth() + 1,
            "dayOfMonth": destinationTravelDate.getDate()
        };
        currentSearch.elementID = currentSearch.elementID + " - "
            + month[destinationTravelDate.getMonth()] + " " + destinationTravelDate.getDate();
    }

    return currentSearch;
}

function updateRecentSearches(currentSearch, recentSearches) {
    var processedSearch = processCurrentSearch(currentSearch);
    var updated = false;
    var now = new Date();
    now.setHours(0, 0, 0, 0);

    var i;
    for (i = recentSearches.length - 1; i >= 0 ; i--) {
        if (recentSearches[i].elementID === processedSearch.elementID) {
            updated = true;
        }

        var dep = new Date(recentSearches[i].originTravelDate.dateTime);
        if (dep < now) {
            recentSearches.splice(i, 1);
        }
    }

    if (!updated) {
        recentSearches.unshift(processedSearch);
    }

    return recentSearches.slice(0, 5);
};
